$primary: rgb(155, 17, 209);
$secondary: rgb(88, 0, 88);
$white: #fff;
$black: #000;
$gray-1: #111;
$gray-2: #222;
$gray-3: #333;
$gray-4: #444;
$gray-5: #555;
$gray-6: #666;
$gray-7: #777;
$gray-8: #888;
$gray-9: #999;
$gray-10: #aaa;
$gray-11: #bbb;
$gray-12: #ccc;
$gray-13: #ddd;
$gray-14: #eee;
$gray-15: #f6f6f6;

@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter.ttf");
}

body {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  overflow-x: hidden;
}

.page {
  min-height: 80vh;
  padding-top: 11vh;
}

.button {
  text-decoration: none;
  padding: 0.6rem 1.2rem;
  background-color: $primary;
  text-transform: uppercase;
  color: $white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  outline: none;
  font-size: 0.85rem;
  font-weight: 500;
  box-shadow: 0 0 10px rgba($gray-1, 0.1);
  @include flex(row, center, center);
  border-radius: 0.3rem;
}

h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: $gray-1;
  margin-bottom: 0.5rem;
  color: $primary;
}

h2 {
  font-size: 3.3rem;
  font-weight: 700;
  color: $gray-1;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  @media only screen and (max-width: 1000px) {
    font-size: 2.8rem;
  }

  span {
    color: $primary;
  }
}

button {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
  color: $black;
}
