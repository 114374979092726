@import "../App.scss";

.header {
  @include flex(row, flex-start, flex-start);
  width: 100%;
  height: 12vh;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 0 10px rgba($gray-1, 0.1);

  .logo {
    padding: 0.5rem 10vw;
    padding-right: 5rem;
    background-color: $primary;
    height: 100%;
    @media only screen and (max-width: 1600px) {
      padding: 0.5rem 2vw;
    }

    @media only screen and (max-width: 1000px) {
      width: 50vw;
      padding: 1rem 2vw;
    }

    @media only screen and (max-width: 400px) {
      width: 40vw;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  .header-rows {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1000px) {
      width: 50vw;
    }

    @media only screen and (max-width: 400px) {
      width: 60vw;
    }

    .header-row {
      @include flex(row, space-between, center);
      width: 100%;
      height: 8vh;
      background-color: $white;
      padding-left: 2rem;
      padding-right: 10vw;

      @media only screen and (max-width: 1600px) {
        padding-right: 2vw;
      }

      &:nth-child(1) {
        height: 4vh;
        background-color: $gray-15;
      }

      .nav {
        @include flex(row, flex-start, center);
        gap: 0.5rem;

        @media only screen and (max-width: 1000px) {
          display: none;
        }

        a {
          text-decoration: none;
          color: $black;
          font-weight: 700;
          font-size: 0.89rem;
          text-transform: uppercase;
          transition: all 0.2s ease-in-out;
          padding: 0.5rem 1rem;
          border-radius: 0.3rem;

          &:hover {
            background-color: $gray-14;
            color: $primary;
          }
        }
      }

      .button {
        @media only screen and (max-width: 1000px) {
          display: none;
        }
      }

      .hamburger {
        position: absolute;
        right: 10vw;

        display: none;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: transparent;

        svg {
          height: 100%;
          width: 100%;
          fill: $black;
        }

        @media only screen and (max-width: 1000px) {
          display: block;
        }
      }

      .social-media {
        @include flex(row, flex-end, center);
        gap: 1rem;
        height: 100%;
        padding: 0.45rem;

        @media only screen and (max-width: 1000px) {
          position: absolute;
          right: 2vw;
          height: 4vh;
          display: none;
        }

        a {
          text-decoration: none;
          height: 100%;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      .contact-info {
        @include flex(row, flex-end, center);
        gap: 2rem;
        height: 100%;
        padding: 0.45rem;

        @media only screen and (max-width: 1000px) {
          display: none;
        }

        a {
          text-decoration: none;
          @include flex(row, center, center);
          gap: 0.3rem;
          &:hover {
            color: $primary;
          }

          span {
            margin-left: 0.5rem;
            font-size: 0.8rem;
            font-weight: 500;
            color: $black;
          }

          svg {
            height: 1.3rem;
            width: 1.3rem;
            fill: $black;
          }
        }
      }
    }
  }

  .mobile-menu {
    @include flex(column, flex-start, flex-start);
    width: 80%;
    height: 100vh;
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    transform: translateX(-100%);
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    border-right: 1px solid $gray-14;
    opacity: 0;
    &.active {
      transform: translateX(0);
      opacity: 1;
    }

    .close {
      position: absolute;
      left: 100%;
      top: 0;
      height: 100%;
      width: 0%;
      background-color: rgba($white, 0.1);
      z-index: -1;
      border: none;
      outline: none;
      backdrop-filter: grayscale(1);
      opacity: 0;
      visibility: hidden;
      transition-delay: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

      &.active {
        visibility: visible;
        opacity: 1;
        width: 100vw;
      }
    }

    .contact-info {
      width: 100%;
      padding: 0.5rem 2rem;
      @include flex(column, flex-start, flex-start);
      background-color: $gray-15;
      gap: 0.5rem;
      position: absolute;
      bottom: 0;

      a {
        text-decoration: none;
        @include flex(row, center, center);
        gap: 0.3rem;
        &:hover {
          color: $primary;
        }

        span {
          margin-left: 0.5rem;
          font-size: 0.8rem;
          font-weight: 500;
          color: $black;
        }

        svg {
          height: 1.3rem;
          width: 1.3rem;
          fill: $black;
        }
      }
    }

    .logo {
      padding: 0.5rem 2rem;
      padding-right: 5rem;
      background-color: $primary;
      height: 10vh;
      width: 70%;
      @include flex(row, center, center);
      border-radius: 0 0 0.2rem 0;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .nav {
      @include flex(column, flex-start, flex-start);
      width: 100%;
      gap: 0.5rem;

      a {
        text-decoration: none;
        color: $black;
        font-weight: 500;
        font-size: 1.2rem;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;
        padding: 0.5rem 1rem;
        border-radius: 0.3rem;
        width: 100%;
        padding-left: 2rem;
        border-bottom: 1px solid $gray-14;

        &:first-child {
          padding-top: 1rem;
          border-top: 1px solid $gray-14;
        }

        &:hover {
          background-color: $gray-14;
          color: $primary;
        }
      }
    }
  }
}
