@import "../App.scss";

.page {
  .hero-page {
    position: relative;
    height: 50vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0.6);
    }

    h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
    }
  }
  .making-steps {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    padding: 5rem 0;
    overflow: hidden;

    @media only screen and (max-width: 1000px) {
      padding: 2rem 0;
    }

    .title {
      @include flex(column, flex-start, flex-start);
      gap: 0.2rem;
      margin-bottom: 3rem;
      padding-left: 10vw;

      @media only screen and (max-width: 1600px) {
        padding-left: 4vw;
        margin-bottom: 1rem;
      }
    }

    .making-steps-content {
      @include flex(row-reverse, center, center);
      padding: 0 10vw;
      width: 100%;

      @media only screen and (max-width: 1000px) {
        flex-direction: column;
        padding: 0 4vw;
      }

      .image {
        width: 50%;
        height: 70vh;
        overflow: hidden;
        border-radius: 0.5rem;

        @media only screen and (max-width: 1000px) {
          width: 100%;
          height: 60vh;
          padding: 1rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.5rem;
        }
      }

      .steps {
        width: 50%;
        height: 70vh;
        @include flex(column, center, center);
        padding-right: 4rem;
        padding-left: 0;
        padding-top: 0;

        @media only screen and (max-width: 1000px) {
          width: 100%;
          height: auto;
          padding: 1rem;
        }

        .step {
          @include flex(column, flex-start, flex-start);
          gap: 1rem;
          padding: 1rem 0;
          border-bottom: 1px solid $gray-13;

          @media only screen and (max-width: 1000px) {
            width: 100%;
          }

          .row-1 {
            @include flex(row, flex-start, flex-start);
            gap: 3rem;

            @media only screen and (max-width: 1000px) {
              width: 100%;
            }

            h2 {
              font-size: 2rem;
              font-weight: 700;
              opacity: 1;
              line-height: 1;
              height: 2rem;

              @media only screen and (max-width: 1000px) {
                font-size: 1.2rem !important;
                height: auto !important;
              }

              &:nth-child(1) {
                opacity: 0.3;
                font-weight: 800;
              }
            }
          }

          .row-2 {
            @include flex(row, flex-start, flex-start);
            gap: 1rem;
            height: 0;
            p {
              font-size: 0.85rem;
              font-weight: 400;
              color: $black;
              opacity: 1;
              line-height: 1;
              padding: 0.5rem 0;
              opacity: 0;
            }
          }

          &.active {
            .row-1 {
              h2 {
                opacity: 1;

                &:nth-child(1) {
                  color: $primary;
                }
              }
            }

            .row-2 {
              height: auto;
              opacity: 1;

              p {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .about-us {
    @include flex(row, center, center);
    padding: 5rem 10vw;
    position: relative;
    gap: 2%;
    overflow: hidden;

    @media only screen and (max-width: 1600px) {
      padding: 5rem 2vw;
    }

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
    }

    .bubles {
      position: absolute;
      right: 0;
      bottom: 0%;
      transform: rotate(180deg);
      height: 40vh;
      z-index: -1;
      max-width: 30vw;
    }

    .about-us-image {
      width: 50%;
      height: 70vh;
      border-radius: 0.5rem;
      overflow: hidden;
      box-shadow: 0 0 30px rgba($black, 0.2);

      @media only screen and (max-width: 1000px) {
        width: 100%;
        height: 50vh;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .about-us-content {
      @include flex(column, flex-start, flex-start);
      width: 48%;
      padding-left: 1.5rem;

      @media only screen and (max-width: 1000px) {
        width: 100%;
        padding-left: 2vw;
        padding-top: 2rem;
      }

      p {
        width: 70%;
        margin: 1rem 0;

        @media only screen and (max-width: 1000px) {
          width: 90%;
          margin: 0;
        }
      }

      .about-us-content-rows {
        @include flex(column, flex-start, flex-start);
        gap: 1rem;
        padding: 1rem 0;

        .about-us-content-row {
          @include flex(row, flex-start, center);
          gap: 1rem;

          svg {
            width: 2.5rem;
            height: 2.5rem;
            color: $white;
            padding: 0.5rem;
            background-color: $primary;
            border-radius: 50%;
            box-shadow: 0 0 30px rgba($black, 0.2);
          }

          span {
            font-size: 1.1rem;
            font-weight: 700;
            color: $black;
            margin-left: 0.5rem;
          }
        }
      }

      .about-us-content-years {
        @include flex(column, flex-start, flex-start);
        padding: 1rem 0;

        h3 {
          font-size: 2.5rem;
          font-weight: 700;
          color: $black;
          padding-left: 0.5rem;
        }

        h4 {
          font-size: 0.9rem;
          font-weight: 700;
        }
      }
    }
  }
}
