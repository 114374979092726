@import "../App.scss";

.places__carousel__container {
  @include flex(column, flex-start, flex-start);
  position: relative;
  overflow-x: hidden;
  width: 100vw;

  @media only screen and (max-width: 1000px) {
    transform: translateY(-5vh);
  }

  .title {
    padding: 3vh 10vw;

    @media only screen and (max-width: 1000px) {
      padding: 3vh 4vw;
    }
  }

  .places__carousel {
    @include flex(row, flex-start, flex-start);
    height: 70vh;
    transition: transform 0.7s ease-in-out; // Smooth transition for sliding

    @media only screen and (max-width: 1000px) {
      height: 50vh;
    }

    .place {
      width: 70vw;
      height: 100%;
      opacity: 0.7;
      position: relative;
      padding: 0 0.5rem;
      transition: all 0.3s ease-in-out;

      &.active {
        opacity: 1;
      }

      .place__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.75);
        box-shadow: 0 0 10px rgba($black, 0.2);
        border-radius: 0.5rem;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .places__carousel__buttons {
    width: 100vw;
    @include flex(row, center, center);
    gap: 1rem;
    padding: 4vh 0;

    @media only screen and (max-width: 1000px) {
      gap: 0.5rem;
    }

    button {
      width: 3rem;
      height: 3px;
      background-color: $gray-10;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border-radius: 5px;

      @media only screen and (max-width: 1000px) {
        width: 2rem;
      }

      &.active {
        background-color: $primary;
      }

      &:hover {
        background-color: $primary;
      }
    }
  }

  .places__carousel__button__left {
    position: absolute;
    left: 0;
    top: 0%;
    z-index: 10;
    height: 100%;
    z-index: 10;
    width: 15vw;
    background-color: transparent;
  }

  .places__carousel__button__right {
    left: 85vw;
    position: absolute;
    top: 0%;
    z-index: 10;
    height: 100%;
    z-index: 10;
    width: 15vw;
    background-color: transparent;
  }
}
