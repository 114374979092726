@import "../App.scss";

.contact-form {
  width: 100%;
  @include flex(row, center, center);
  background-color: $gray-2;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }

  .form-container {
    width: 40%;
    @include flex(column, flex-start, flex-start);
    gap: 1rem;
    padding: 5vh 3rem;
    padding-left: 10vw;

    @media only screen and (max-width: 1000px) {
      width: 100%;
      padding: 10vh 10vw;
    }

    h2 {
      color: $white;
    }

    .form {
      @include flex(column, center, center);
      gap: 0.5rem;
      width: 100%;

      .form-row {
        @include flex(row, center, center);
        width: 100%;
        gap: 0.7rem;

        .input-container {
          width: 100%;
          @include flex(column, flex-start, flex-start);
          gap: 0.2rem;

          span {
            color: $white;
            font-size: 0.75rem;
          }

          input {
            width: 100%;
            padding: 0.7rem 1rem;
            border: none;
            outline: none;
            border-radius: 0.2rem;
            background-color: $gray-15;
            color: $black;
            font-size: 0.9rem;
            font-weight: 500;
            transition: all 0.3s ease-in-out;

            &:focus {
              background-color: $white;
            }
          }

          textarea {
            width: 100%;
            padding: 0.5rem 1rem;
            border: none;
            outline: none;
            border-radius: 0.2rem;
            background-color: $gray-15;
            color: $black;
            font-size: 0.9rem;
            font-weight: 500;
            transition: all 0.3s ease-in-out;
            resize: none;

            &:focus {
              background-color: $white;
            }
          }
        }
        .button {
          margin-top: 1rem;
          width: 100%;
          padding: 0.7rem 1rem;
          border: none;
          outline: none;
          border-radius: 0.2rem;
          background-color: $primary;
          color: $white;
          font-size: 0.85rem;
          font-weight: 600;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
        }
      }
    }

    .whats-upp {
      @include flex(row, flex-start, flex-start);
      gap: 1rem;
      margin-top: 2rem;

      img {
        width: 3.5rem;
        height: 3.5rem;
      }

      .whats-upp-text {
        @include flex(column, flex-start, flex-start);
        gap: 0.2rem;

        h3 {
          color: $white;
          font-size: 1rem;
          font-weight: 600;
        }

        span {
          color: $white;
          font-size: 1rem;
        }
      }
    }
  }

  .contact-map {
    width: 70%;
    background-color: $gray-15;
    height: 90vh;

    @media only screen and (max-width: 1000px) {
      width: 100%;
      height: 50vh;
    }

    .map-container {
      width: 100%;
      height: 100%;
    }
  }
}
