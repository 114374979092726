@import "../App.scss";

.home {
  .hero {
    height: 83vh;
    background-color: rgba($color: #fff, $alpha: 0.5);
    position: relative;
    @media only screen and (max-width: 1000px) {
      height: 70vh;
    }

    @media only screen and (max-width: 400px) {
      height: 90vh;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .hero-content {
      position: absolute;
      left: 10vw;
      top: 50%;
      transform: translateY(-50%);
      @include flex(column, flex-start, flex-start);

      @media only screen and (max-width: 1600px) {
        left: 2vw;
      }

      @media only screen and (max-width: 1000px) {
        left: 50%;
        transform: translateX(-50%) translateY(-90%);
        width: 90%;
      }

      h4 {
        color: $primary;

        @media only screen and (max-width: 1000px) {
          font-size: 0.85rem;
          width: 100%;
          text-align: center;
        }
      }

      .note {
        color: $gray-3;
        font-size: 0.7rem;
        font-weight: 600;
        line-height: 1.3;

        @media only screen and (max-width: 1000px) {
          font-size: 0.7rem;
          width: 100%;
          text-align: center;
        }
      }

      h2 {
        width: 50rem;
        font-size: 4rem;
        color: $black;
        font-weight: 700;

        @media only screen and (max-width: 1600px) {
          width: 70%;
        }

        @media only screen and (max-width: 1000px) {
          width: 100%;
          font-size: 2rem;
          text-align: center;
        }
      }

      p {
        width: 30rem;
        margin-bottom: 1.5rem;
        color: $gray-3;
        font-weight: 600;
        font-size: 0.9rem;

        @media only screen and (max-width: 1600px) {
          width: 70%;
        }

        @media only screen and (max-width: 1000px) {
          width: 90%;
          font-size: 0.6rem;
          text-align: center;
          display: none;
        }
      }

      .prices {
        @include flex(row, center, center);
        gap: 2rem;
        padding: 0.4rem 1rem;
        background-color: #ffffffb4;
        border-radius: 1rem;
        margin: 0.3rem 0;
        margin-bottom: 1rem;
      }

      .price {
        @include flex(row, center, center);
        gap: 2rem;
        .price-per-meter {
          @include flex(column, flex-start, flex-start);
          gap: 0.2rem;

          @media only screen and (max-width: 1000px) {
            @include flex(column, center, center);
          }

          h3 {
            font-size: 2.3rem;
            font-weight: 600;
            color: $black;

            @media only screen and (max-width: 1000px) {
              font-size: 1.5rem;
              width: 100%;
              text-align: center;
            }
          }

          h5 {
            font-size: 1rem;
            font-weight: 800;
            color: $gray-3;
            width: 100%;
            text-align: center;

            @media only screen and (max-width: 1000px) {
              font-size: 0.7rem;
            }
          }
        }
      }
    }

    .hero-footer {
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      left: 10vw;
      background-color: $primary;
      padding: 1.5rem 2rem;
      @include flex(row, center, center);
      gap: 2rem;
      box-shadow: 0 0 30px rgba($black, 0.2);
      border-radius: 0.3rem;

      @media only screen and (max-width: 1600px) {
        left: 2vw;
      }

      @media only screen and (max-width: 1000px) {
        @include flex(column, center, center);
        padding: 1rem 1.5rem;
        left: 50%;
        transform: translateX(-50%) translateY(50%);
        gap: 1rem;
      }

      .hero-footer-item {
        @include flex(column, center, center);

        h3 {
          font-size: 2rem;
          font-weight: 600;
          color: $white;
          line-height: 1.3;

          @media only screen and (max-width: 1000px) {
            font-size: 1.5rem;
          }
        }

        h5 {
          font-size: 0.8rem;
          font-weight: 500;
          color: $white;
          text-transform: uppercase;

          @media only screen and (max-width: 1000px) {
            font-size: 0.78rem;
          }
        }

        p {
          font-size: 0.8rem;
          font-weight: 500;
          color: $white;
          width: 20vw;

          @media only screen and (max-width: 1600px) {
            width: 70vw;
            text-align: center;
          }
        }

        a {
          width: 4.5rem;
          height: 4.5rem;
          border-radius: 50%;
          background-color: $white;
          margin: 0 1rem;
          border: 0.5rem solid #ea81ff;
          position: relative;
          animation: scaleLoop 1.5s infinite ease-in-out;

          .triangle {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 0.2rem;
            background-color: $primary;
            //make a triangle
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            //rotate the triangle
            transform: rotate(90deg) translate(-45%, 35%);
          }
        }
      }
    }

    .hero-free-delivery {
      position: absolute;
      bottom: 3rem;
      right: 10vw;
      background-color: $primary;
      padding: 0.5rem 1.5rem;
      border-radius: 5rem;
      height: 3.5rem;
      overflow: visible;
      @include flex(row, center, center);
      padding-left: 0;

      @media only screen and (max-width: 1600px) {
        right: 5vw;
        top: 9rem;
        bottom: auto;
      }

      @media only screen and (max-width: 1000px) {
        height: 2.5rem;
        bottom: 10rem;
        top: auto;
      }

      svg {
        height: 5rem;
        width: 5rem;
        fill: $white;
        margin-right: 1rem;
        border-radius: 50%;
        background-color: $secondary;
        border: 0.4rem solid $primary;
        padding: 0.7rem;

        @media only screen and (max-width: 1000px) {
          height: 3rem;
          width: 3rem;
          padding: 0.4rem;
        }
      }

      h4 {
        color: $white;
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1;
        transform: translateY(15%) translateX(-0.3rem);

        @media only screen and (max-width: 1000px) {
          font-size: 0.9rem;
        }
      }
    }

    .telephone {
      position: absolute;
      bottom: 10rem;
      right: 10vw;
      background-color: #960f0f;
      padding: 0.5rem 1.5rem;
      border-radius: 5rem;
      height: 3.5rem;
      overflow: visible;
      @include flex(row, center, center);
      padding-left: 0;

      @media only screen and (max-width: 1600px) {
        right: 2vw;
        top: 3rem;
        bottom: auto;
      }

      @media only screen and (max-width: 1000px) {
        height: 2.5rem;
        right: 5vw;
        bottom: 15rem;
        top: auto;
      }

      svg {
        height: 5rem;
        width: 5rem;
        fill: #960f0f;
        margin-right: 1rem;
        border-radius: 50%;
        background-color: $white;
        border: 0.4rem solid #960f0f;
        padding: 0.7rem;

        @media only screen and (max-width: 1000px) {
          height: 3rem;
          width: 3rem;
          padding: 0.4rem;
        }
      }

      h4 {
        color: $white;
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 1;
        transform: translateY(15%) translateX(-0.3rem);

        @media only screen and (max-width: 1000px) {
          font-size: 0.9rem;
        }
      }
    }
  }

  .reviews {
    @include flex(column, center, center);
    padding: 5rem 0;
    padding-top: 30vh;
    position: relative;
    @media only screen and (max-width: 1600px) {
      padding-bottom: 0;
    }

    @media only screen and (max-width: 1000px) {
      padding-top: 25vh;
      justify-content: flex-end;
    }

    .next,
    .prev {
      position: absolute;
      left: 10%;
      top: 60%;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0.5rem;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0 0 30px rgba($black, 0.1);
      background-color: $white;
      border: 1px solid $gray-14;
      z-index: 10;
      cursor: pointer;

      @media only screen and (max-width: 1000px) {
        left: 2%;
      }

      &.next {
        left: auto;
        right: 10%;

        @media only screen and (max-width: 1000px) {
          right: 2%;
        }
      }

      svg {
        color: $black;
        width: 2rem;
        height: 2rem;
      }
    }

    .bubles {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 35vh;
      z-index: -1;
      max-width: 40vw;
    }

    h3 {
      font-size: 1.7rem;
      font-weight: 700;
      color: $gray-1;
      margin-bottom: 1rem;
      text-align: center;

      @media only screen and (max-width: 1000px) {
        font-size: 1.5rem;
        width: 90%;
        text-align: center;
      }
    }

    h7 {
      font-size: 1.2rem;
      font-weight: 700;
      color: $gray-4;
      margin-bottom: 1rem;
      text-align: center;

      @media only screen and (max-width: 1000px) {
        font-size: 1.5rem;
        width: 90%;
        text-align: center;
      }
    }

    .google {
      @include flex(row, center, flex-end);
      gap: 1rem;

      h6 {
        font-size: 1.1rem;
        font-weight: 600;
        color: $gray-1;
        line-height: 1;

        @media only screen and (max-width: 1000px) {
          font-size: 1rem;
        }
      }

      span {
        font-size: 1.7rem;
        font-weight: 500;
        color: rgb(241, 207, 14);
        line-height: 1;
        transform: translateY(0.25rem);

        @media only screen and (max-width: 1000px) {
          font-size: 1.3rem;
        }
      }

      p {
        font-size: 1.1rem;
        font-weight: 700;
        color: $gray-2;
        line-height: 1;

        @media only screen and (max-width: 1000px) {
          font-size: 1rem;
        }
      }
    }
  }

  .about-us {
    @include flex(row, center, center);
    padding: 5rem 10vw;
    position: relative;
    gap: 2%;
    overflow: hidden;

    @media only screen and (max-width: 1600px) {
      padding: 5rem 2vw;
    }

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
    }

    .bubles {
      position: absolute;
      right: 0;
      bottom: 0%;
      transform: rotate(180deg);
      height: 40vh;
      z-index: -1;
      max-width: 30vw;
    }

    .about-us-image {
      width: 50%;
      height: 70vh;
      border-radius: 0.5rem;
      overflow: hidden;
      box-shadow: 0 0 30px rgba($black, 0.2);

      @media only screen and (max-width: 1000px) {
        width: 100%;
        height: 50vh;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .about-us-content {
      @include flex(column, flex-start, flex-start);
      width: 48%;
      padding-left: 1.5rem;

      @media only screen and (max-width: 1000px) {
        width: 100%;
        padding-left: 2vw;
        padding-top: 2rem;
      }

      p {
        width: 70%;
        margin: 1rem 0;

        @media only screen and (max-width: 1000px) {
          width: 90%;
          margin: 0;
        }
      }

      .about-us-content-rows {
        @include flex(column, flex-start, flex-start);
        gap: 1rem;
        padding: 1rem 0;

        .about-us-content-row {
          @include flex(row, flex-start, center);
          gap: 1rem;

          svg {
            width: 2.5rem;
            height: 2.5rem;
            color: $white;
            padding: 0.5rem;
            background-color: $primary;
            border-radius: 50%;
            box-shadow: 0 0 30px rgba($black, 0.2);
          }

          span {
            font-size: 1.1rem;
            font-weight: 700;
            color: $black;
            margin-left: 0.5rem;
          }
        }
      }

      .about-us-content-years {
        @include flex(column, flex-start, flex-start);
        padding: 1rem 0;

        h3 {
          font-size: 2.5rem;
          font-weight: 700;
          color: $black;
          padding-left: 0.5rem;
        }

        h4 {
          font-size: 0.9rem;
          font-weight: 700;
        }
      }
    }
  }

  .free-delivery-section {
    padding: 3rem 10vw;
    position: relative;
    width: 100%;
    background-color: rgba($color: $primary, $alpha: 0.8);

    @media only screen and (max-width: 1600px) {
      padding: 3rem 2vw;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .free-delivery-section-content {
      width: 100%;
      @include flex(row, center, center);
      gap: 1rem;

      @media only screen and (max-width: 1000px) {
        flex-direction: column;
      }

      h2 {
        color: $white;
        font-size: 5rem;
        font-weight: 700;
        width: 70%;

        @media only screen and (max-width: 1000px) {
          font-size: 3rem;
          width: 100%;
          text-align: center;
        }

        span {
          color: $white;
        }
      }

      p {
        color: $white;
        font-size: 1.2rem;
        font-weight: 600;
        width: 30%;

        @media only screen and (max-width: 1000px) {
          font-size: 0.9rem;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .content-1 {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 5rem 10vw;
    @include flex(row, center, center);

    @media only screen and (max-width: 1600px) {
      padding: 5rem 3vw;
    }

    @media only screen and (max-width: 1000px) {
      padding: 0;
      @include flex(column-reverse, center, center);
    }

    img {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: -1;

      @media only screen and (max-width: 1000px) {
        width: 100%;
        height: 50%;
        position: static;
      }

      &:nth-child(1) {
        left: auto;
        right: 0;
      }
    }

    .content-1-content {
      z-index: 10;
      @include flex(column, flex-start, flex-start);
      gap: 1rem;
      width: 50%;
      background-color: $gray-1;
      border-radius: 1rem;
      position: relative;

      @media only screen and (max-width: 1000px) {
        width: 100%;
        border-radius: 0;
      }

      .container {
        width: 100%;
        height: 100%;
        @include flex(column, center, center);
        background-color: $gray-1;
        padding: 8vh 4rem;
        border-radius: 1rem;
        gap: 1rem;

        @media only screen and (max-width: 1000px) {
          padding: 2rem;
          border-radius: 0;
        }

        h2 {
          color: $white;
          font-size: 3rem;
          width: 100%;
          text-align: center;
        }

        p {
          color: $gray-12;
          font-size: 0.85rem;
          font-weight: 400;
          width: 100%;
          text-align: center;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: -0.75rem;
        left: -0.75rem;
        height: calc(100% + 1.5rem);
        width: calc(100% + 1.5rem);
        background-color: $primary;
        border-radius: 1rem;
        z-index: -1;
        opacity: 0.2;

        @media only screen and (max-width: 1000px) {
          display: none;
        }
      }

      .video-button {
        @include flex(row, center, center);
        gap: 1rem;
        background-color: transparent;
        border: none;
        outline: none;
        text-decoration: none;
        margin-top: 1.3rem;

        span {
          font-size: 1rem;
          font-weight: 600;
          color: $white;
        }

        .video-button-icon {
          width: 3.8rem;
          height: 3.8rem;
          border-radius: 50%;
          background-color: rgba($color: #fff, $alpha: 0.3);
          border-radius: 50%;
          padding: 0.3rem;
          animation: scaleLoop 1.5s infinite ease-in-out;

          svg {
            width: 100%;
            height: 100%;
            padding: 0.8rem;
            background-color: $white;
            border-radius: 50%;
            color: $primary;
            fill: $primary;
          }
        }
      }

      @media only screen and (max-width: 1000px) {
        width: 100%;
        padding: 1rem 2vw;
      }
    }
  }

  .making-steps {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    padding: 5rem 0;
    overflow: hidden;

    @media only screen and (max-width: 1000px) {
      padding: 2rem 0;
    }

    .title {
      @include flex(column, flex-start, flex-start);
      gap: 0.2rem;
      margin-bottom: 3rem;
      padding-left: 10vw;

      @media only screen and (max-width: 1600px) {
        padding-left: 4vw;
        margin-bottom: 1rem;
      }
    }

    .making-steps-content {
      @include flex(row-reverse, center, center);
      padding: 0 10vw;
      width: 100%;

      @media only screen and (max-width: 1000px) {
        flex-direction: column;
        padding: 0 4vw;
      }

      .image {
        width: 50%;
        height: 70vh;
        overflow: hidden;
        border-radius: 0.5rem;

        @media only screen and (max-width: 1000px) {
          width: 100%;
          height: 60vh;
          padding: 1rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.5rem;
        }
      }

      .steps {
        width: 50%;
        height: 70vh;
        @include flex(column, center, center);
        padding-right: 4rem;
        padding-left: 0;
        padding-top: 0;

        @media only screen and (max-width: 1000px) {
          width: 100%;
          height: auto;
          padding: 1rem;
        }

        .step {
          @include flex(column, flex-start, flex-start);
          gap: 1rem;
          padding: 1rem 0;
          border-bottom: 1px solid $gray-13;

          @media only screen and (max-width: 1000px) {
            width: 100%;
          }

          .row-1 {
            @include flex(row, flex-start, flex-start);
            gap: 3rem;

            @media only screen and (max-width: 1000px) {
              width: 100%;
            }

            h2 {
              font-size: 2rem;
              font-weight: 700;
              opacity: 1;
              line-height: 1;
              height: 2rem;

              @media only screen and (max-width: 1000px) {
                font-size: 1.2rem !important;
                height: auto !important;
              }

              &:nth-child(1) {
                opacity: 0.3;
                font-weight: 800;
              }
            }
          }

          .row-2 {
            @include flex(row, flex-start, flex-start);
            gap: 1rem;
            height: 0;
            p {
              font-size: 0.85rem;
              font-weight: 400;
              color: $black;
              opacity: 1;
              line-height: 1;
              padding: 0.5rem 0;
              opacity: 0;
            }
          }

          &.active {
            .row-1 {
              h2 {
                opacity: 1;

                &:nth-child(1) {
                  color: $primary;
                }
              }
            }

            .row-2 {
              height: auto;
              opacity: 1;

              p {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .questions {
    width: 100%;
    @include flex(column, flex-start, flex-start);
    padding: 10vh 0;
    background-color: rgba($color: $primary, $alpha: 0.04);

    .questions-container {
      width: 100%;
      padding: 0 10vw;
      @include flex(column, flex-start, flex-start);

      @media only screen and (max-width: 1000px) {
        padding: 0 4vw;
      }

      .question {
        width: 100%;
        @include flex(column, flex-start, flex-start);
        padding: 0.2rem;
        border-bottom: 1px solid $gray-13;

        .question-button {
          @include flex(row, space-between, center);
          width: 100%;
          background-color: transparent;
          padding: 1rem 0;
          cursor: pointer;

          span {
            font-size: 1.2rem;
            font-weight: 500;
            color: $gray-3;

            @media only screen and (max-width: 1000px) {
              font-size: 1rem;
            }

            &.active {
              color: $black;
            }
          }

          svg {
            width: 1.5rem;
            height: 1.5rem;
            fill: $black;

            &.active {
              transform: rotate(180deg);
            }

            @media only screen and (max-width: 1000px) {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }

        p {
          height: 0;
          opacity: 0;
          font-size: 0.93rem;
          font-weight: 400;
          color: $gray-3;
          padding: 0;
          transition: all 0.3s ease-in-out;

          &.active {
            height: auto;
            opacity: 1;
            padding: 1rem 0;
          }
        }
      }
    }

    .title {
      padding-left: 10vw;

      @media only screen and (max-width: 1600px) {
        padding: 0 4vw;
      }
    }
  }
}

@keyframes scaleLoop {
  0% {
    transform: scale(1.07);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.07);
  }
}
