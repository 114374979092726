@import "../App.scss";

.footer {
  @include flex(column, center, center);
  width: 100%;
  background-color: $gray-1;

  .logo {
    width: 15rem;
    margin-bottom: 0.2rem;
  }

  p {
    color: $gray-14;
    font-size: 0.85rem;
    font-weight: 300;
    max-width: 40rem;
  }

  .socials {
    @include flex(row, center, center);
    margin-top: 0.2rem;

    a {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background-color: $gray-15;
      margin: 0 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: $gray-14;
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .footer-bottom {
    @include flex(row, flex-end, center);
    margin: 0 10vw;
    padding: 1rem 0;
    width: 80%;
    border-top: 1px solid $gray-3;
    position: relative;
    height: 5vh;

    .center {
      color: $gray-14;
      font-size: 0.8rem;
      font-weight: 300;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @media only screen and (max-width: 1000px) {
        font-size: 0.7rem;
        width: 100%;
      }
    }

    a {
      color: $gray-14;
      font-size: 0.8rem;
      font-weight: 300;
      transition: all 0.2s ease-in-out;

      @media only screen and (max-width: 1000px) {
        display: none;
      }

      span {
        color: $primary;
        font-weight: 700;
        font-size: 0.9rem;
        padding-left: 0.5rem;
      }
    }
  }

  .footer-sections {
    @include flex(row, flex-start, flex-start);
    width: 100%;
    padding: 5rem 10vw;
    gap: 5rem;
    flex-wrap: wrap;

    .footer-section {
      @include flex(column, flex-start, flex-start);
      gap: 2rem;

      h5 {
        font-size: 1.6rem;
        font-weight: 600;
        color: $white;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
      }

      .footer-section-links {
        @include flex(column, flex-start, flex-start);
        gap: 0.5rem;

        a {
          text-decoration: none;
          color: $gray-14;
          font-size: 0.9rem;
          font-weight: 400;
          transition: all 0.2s ease-in-out;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
